import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import authService from "./services/authService";
import { history } from "./_helpers/history";
// import ProtectedRoute from "./_components/ProtectedRoute";
import AdminProtectedRoute from "./_components/AdminProtectedRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Admin Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Member Containers
// const TheMemberLayout = React.lazy(() =>
//   import("./containers/member/TheMemberLayout")
// );

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/resetPassword/ResetPassword")
);
// const AccountVerification = React.lazy(() =>
//   import("./views/pages/accountVerification/AccountVerification")
// );
// const SettlementProgram = React.lazy(() =>
//   import("./views/pages/settlementProgram/SettlementProgram")
// );
// const AssetSettlement = React.lazy(() =>
//   import("./views/pages/settlementProgram/AssetSettlement")
// );
// const TopUp = React.lazy(() => import("./views/pages/settlementProgram/TopUp"));
// const SettlementSchedule = React.lazy(() =>
//   import("./views/pages/settlementProgram/SettlementSchedule")
// );
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

export function App() {
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = authService.getCurrentUser();
    setUser({ user });
  }, []);

  return (
    <BrowserRouter history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} user={user} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          {/* <Route
            exact
            path="/account-verification"
            name="Account Verification Page"
            render={(props) => <AccountVerification {...props} />}
          />
          <ProtectedRoute
            exact
            path="/settlement-program"
            name="Settlement Program Page"
            render={(props) => <SettlementProgram {...props} user={user} />}
          />
          <ProtectedRoute
            exact
            path="/settlement-program/asset-settlement"
            name="Asset Settlement Program Page"
            render={(props) => <AssetSettlement {...props} user={user} />}
          />
          <ProtectedRoute
            exact
            path="/settlement-program/top-up"
            name="Top Up Asset Settlement Program Page"
            render={(props) => <TopUp {...props} user={user} />}
          />
          <ProtectedRoute
            exact
            path="/settlement-program/schedule"
            name="Schedule Page"
            render={(props) => <SettlementSchedule {...props} user={user} />}
          /> */}
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          {/* <ProtectedRoute
            path="/member/"
            name="Member"
            render={(props) => <TheMemberLayout {...props} user={user} />}
          /> */}
          <AdminProtectedRoute
            path="/admin/"
            name="Admin"
            render={(props) => <TheLayout {...props} />}
          />
          <Redirect to="/admin/dashboard" />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
